import React, { useState, useRef, useEffect } from "react"
import { addPropertyControls, ControlType } from "framer"

const styles: { [key: string]: React.CSSProperties } = {
    container: {
        width: "100%",
        fontFamily: "Inter, sans-serif",
    },
    questionContainer: {
        backgroundColor: "#F1EAE2",
        borderRadius: "4px",
        cursor: "pointer",
        overflow: "hidden",
        width: "100%",
    },
    question: {
        padding: "30px",
        fontFamily: "Inter, sans-serif",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "150%",
        color: "#020407",
        letterSpacing: "-1%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    answer: {
        overflow: "hidden",
        transition: "height 0.3s ease-out",
        padding: "0 30px",
    },
    answerText: {
        fontFamily: "Inter, sans-serif",
        fontSize: "20px",
        fontWeight: 400,
        lineHeight: "150%",
        color: "#020407",
        letterSpacing: "-2%",
        margin: "0",
        paddingBottom: "30px",
    },
    icon: {
        transition: "transform 0.3s ease-out",
    },
}

const ChevronIcon = ({ isOpen }) => (
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        style={{
            ...styles.icon,
            transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
        }}
    >
        <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
)

interface FAQItem {
    question: string
    answer: string
}

interface FAQComponentProps {
    data: FAQItem[]
}

export function FAQComponent({ data }: FAQComponentProps) {
    const [openIndex, setOpenIndex] = useState<number | null>(null)
    const answerRefs = useRef<(HTMLDivElement | null)[]>([])

    useEffect(() => {
        answerRefs.current = answerRefs.current.slice(0, data.length)
    }, [data])

    const toggleQuestion = (index: number) => {
        setOpenIndex(openIndex === index ? null : index)
    }

    useEffect(() => {
        answerRefs.current.forEach((ref, index) => {
            if (ref) {
                if (index === openIndex) {
                    ref.style.height = `${ref.scrollHeight}px`
                } else {
                    ref.style.height = "0px"
                }
            }
        })
    }, [openIndex])

    return (
        <div style={styles.container}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                }}
            >
                {data.map((item, index) => (
                    <div key={index} style={styles.questionContainer}>
                        <div
                            style={styles.question}
                            onClick={() => toggleQuestion(index)}
                        >
                            {item.question}
                            <ChevronIcon isOpen={openIndex === index} />
                        </div>
                        <div
                            ref={(el) => (answerRefs.current[index] = el)}
                            style={styles.answer}
                        >
                            <p style={styles.answerText}>{item.answer}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

FAQComponent.defaultProps = {
    data: [
        {
            question: "What is an algorithm?",
            answer: "An algorithm is a set of rules or instructions given to an AI system to perform a task or solve a problem. Algorithms can be rule-based or learned through machine learning.",
        },
        {
            question: "What is machine learning?",
            answer: "Machine learning is a subset of artificial intelligence that involves training systems to learn from data and improve their performance on a specific task over time without being explicitly programmed.",
        },
    ],
}

addPropertyControls(FAQComponent, {
    data: {
        type: ControlType.Array,
        title: "FAQ Items",
        propertyControl: {
            type: ControlType.Object,
            controls: {
                question: { type: ControlType.String, title: "Question" },
                answer: {
                    type: ControlType.String,
                    title: "Answer",
                    displayTextArea: true,
                },
            },
        },
    },
})
